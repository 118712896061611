import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

var firebaseConfig = {
  apiKey: 'AIzaSyAEJnxkLQ5ACj-kabgq9sktxcULH6UN2eE',
  authDomain: 'email-signature-1383f.firebaseapp.com',
  projectId: 'email-signature-1383f',
  storageBucket: 'email-signature-1383f.appspot.com',
  messagingSenderId: '311357215790',
  appId: '1:311357215790:web:cc5a86cedb0fe432697bbf',
  measurementId: 'G-6H3S195GRY',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export { storage, firebase as default };
