import React, { useEffect, useState } from 'react';
import Hello from './hello';
import ImageUploading from 'react-images-uploading';
import { storage } from './firebase';
import { v4 as uuidv4 } from 'uuid';
import './App.css';

function App() {
  const [copied, setCopied] = useState(false);
  const [name, setName] = useState('Ruban Nanthagopal');
  const [designation, setDesignation] = useState('Founder');
  const [mobile, setMobile] = useState('+94 777 745862');
  const [email, setEmail] = useState('ruban@cabbageapps.com');
  const [images, setImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([{file: new Blob()}]);
  const [initialProfilePicture] = useState(
    'https://d36urhup7zbd7q.cloudfront.net/fc4402cf-1a69-458b-b99a-69774385f9b9/EmployeeProfilepictures.crop_788x788_58,0.preview.format_png.resize_200x.png#logo'
  );
  const [discoveryCallLink, setDiscoveryCallLink] = useState('');
  const [linkedInLink, setLInkedInLInk] = useState(
    'https://lk.linkedin.com/company/cabbageapps'
  );
  const [url, setURL] = useState('');

  const onChange = (imageList:any) => {
    setImages(imageList);
    setSelectedImages(imageList);
  };

  const handleUpload = (e:any) => {
    e.preventDefault();
    const ref = storage.ref(`/images/${uuidv4()}`);
    const uploadTask = ref.put(selectedImages[0].file);
    uploadTask.on('state_changed', console.log, console.error, () => {
      ref.getDownloadURL().then((urlPic) => {
        setURL(urlPic);
        console.log(urlPic);
        
      });
    });
  };

  const copyToClipboard = () => {
    let copyText = document.querySelector('.signature');
    const range = document.createRange();
    if (copyText) {
      range.selectNode(copyText);
    }
    const windowSelection = window.getSelection();
    if (windowSelection) {
      windowSelection.removeAllRanges();
      windowSelection.addRange(range);
    }
    try {
      let successful = document.execCommand('copy');
      setCopied(true);
    } catch (err) {
      console.log('Fail');
    }
  };

  useEffect(() => {
    setURL('');
  }, [images]);

  return (
    <div className="App">
      <header className="App-header">
        <div className="filter"></div>
        <div className="header-wrapper">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/cabbage-mail-signature.appspot.com/o/ca-logo.png?alt=media&token=51dcca59-2e21-45ad-9ac2-85b8c559f129"
            alt="Cabbage Apps"
          />
          <ul>
            <h1>Build Your Signature Here</h1>
            <li>1. Fill in your details</li>
            <li>2. Click copy button</li>
            <li>
              3. Open Gmail `{'>'}` Settings`{'>'}` Command + V / Ctrl + V (
              paste into signature box) `{'>'}`Save
            </li>
            <li>4. Done! </li>
          </ul>
        </div>
      </header>
      <div className="App-intro">
        <div className="field-wrapper">
          <h5>Enter your Name</h5>
          <input
            placeholder="Your Name Here"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="field-wrapper">
          <h5>Enter your Phone Number</h5>
          <input
            placeholder="Your Phone Number"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
        </div>
        <div className="field-wrapper">
          <h5>Enter your Designation</h5>
          <input
            placeholder="Your Designation"
            value={designation}
            onChange={(e) => setDesignation(e.target.value)}
          />
        </div>
        <div className="field-wrapper">
          <h5>Enter your Email</h5>
          <input
            placeholder="Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="field-wrapper">
          <h5>Enter Discover Call Link Or Leave it blank</h5>
          <input
            placeholder="Your Discover Call Link"
            value={discoveryCallLink}
            onChange={(e) => setDiscoveryCallLink(e.target.value)}
          />
        </div>
        <div className="field-wrapper">
          <h5>Enter Your LinkedIn Link</h5>
          <input
            placeholder="Your LinkedIn Link"
            value={linkedInLink}
            onChange={(e) => setLInkedInLInk(e.target.value)}
          />
        </div>
        <div className="field-wrapper">
          <h5>Select Your Avatar</h5>
        </div>
        <ImageUploading
          value={images}
          onChange={onChange}
          dataURLKey="data_url"
        >
          {({ onImageUpload, onImageRemoveAll, isDragging, dragProps }) => (
            <div className="field-wrapper">
              {images.length === 0 ? (
                <button
                  className="button copyButton"
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  Select Image
                </button>
              ) : (
                <div>
                  <button onClick={handleUpload} style={{ color: 'red' }}>
                    Set Image
                  </button>
                  <h5 onClick={onImageRemoveAll} style={{ cursor: 'pointer' }}>
                    Click here to Remove Image
                  </h5>
                </div>
              )}
            </div>
          )}
        </ImageUploading>
      </div>
      <hr />
      <div className="signature-container">
        <Hello
          name={name}
          email={email}
          mobile={mobile}
          designation={designation}
          profilePicture={url}
          initialProfilePicture={initialProfilePicture}
          discoveryCallLink={discoveryCallLink}
          linkedInLink={linkedInLink}
        />
      </div>
      <hr />
      <p>
        {url && (
          <div className="field-wrapper">
            <button className="button copyButton" onClick={copyToClipboard}>
              {copied ? 'Copied!' : 'Copy'}
            </button>
          </div>
        )}
      </p>
    </div>
  );
}

export default App;
